body {
  font-family: 'source code pro', 'consolas', 'monospace';
  position: relative;
  padding: 30px;
  background-color: #282c34;
}

.App-header {
  font-family: 'Playfair Display', serif;
  font-size: 2.2rem;
  width: 100%;
  text-align: center;
}

.accent {
  color: #2ecc71;
  position: relative;
}

.accent::after {
  content:'';
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 5px;
  border-radius: 5px;
  background-color: #2ecc71;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  color: white;
  position: relative;
  align-items: flex-end;
}

.App-link {
  color: #09d3ac;
}

.messageBox {
  display: flex;
  width: 70%;
  margin: 30px auto;
  flex-direction: column;
}

.message {
  text-align: left;
  line-height: 1.8rem;
  max-width: 350px;
  padding: 20px 30px;
  border-radius: 20px;
  font-size: 1.3rem;
  margin-bottom: 20px;
  box-shadow: 0 1px 1px rgba(0,0,0,0.12), 
              0 2px 2px rgba(0,0,0,0.12), 
              0 4px 4px rgba(0,0,0,0.12), 
              0 8px 8px rgba(0,0,0,0.12),
              0 16px 16px rgba(0,0,0,0.12);
}

.message.ben.image {
  padding: 0;
  background-color: #282c34;
}

.message.ben {
  background-color: #efefef;
  border-radius: 0 15px 15px 15px;
  color: #282c34;
  position: relative;
  left: 40px;
}

.message.ben::before {
  content: '';
  width: 40px;
  height: 40px;
  box-shadow: 0 1px 1px rgba(0,0,0,0.12), 
              0 2px 2px rgba(0,0,0,0.12), 
              0 4px 4px rgba(0,0,0,0.12), 
              0 8px 8px rgba(0,0,0,0.12),
              0 16px 16px rgba(0,0,0,0.12);
  border-radius: 50%;
  position: absolute;
  left: -50px;
  top: 0;
  background-image: url('./0.jpg');
  background-size: cover;
}

.message.user {
  border-radius: 15px 0 15px 15px;
  background-color: #2980b9;
  color: #efefef;
  align-self: flex-end;
  flex-grow: 1;
}

input {
  font-size: 1.3rem;
  background-color: #2c3e50;
  border: none;
  outline: none;
  padding: 15px 30px;
  margin: 40px 0;
  border-radius: 60px;
  color: #2ecc71;
  box-shadow: 0 1px 1px rgba(0,0,0,0.12), 
              0 2px 2px rgba(0,0,0,0.12), 
              0 4px 4px rgba(0,0,0,0.12), 
              0 8px 8px rgba(0,0,0,0.12),
              0 16px 16px rgba(0,0,0,0.12);
  flex-grow: 1;
}

.message img {
  object-fit: cover;
  object-position: 50% 50%;
  width: 100%;
  height: 100%;
  border-radius: 0 15px 15px 15px;
  overflow: hidden;
}

@media (max-width: 640px) {
  .messageBox {
    width: 100%;
    margin: auto;
    flex-direction: column;
  }
  .message.ben {
    left: 0;
  }
  .message.ben::before {
    width: 0;
    height: 0;
  }
}